.login-card {
  width: 420px;
  margin: 100px auto;
  padding: 20px;
  /* padding-top: 100px; */
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans";
}

.login-card form {
  display: flex;
  font-family: "Open Sans";
  flex-direction: column;
}

.inputLabels {
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  outline: #fff;
  width: 400px;
  font-family: "Open Sans";
}

.loginHeader {
  font-family: "Open Sans";
  font-weight: bolder;
  margin-top: 0;
  display: flex;
  padding-bottom: 10px;
  /* border-bottom: 1px solid; */
  align-items: center;
  justify-content: center;
  border-color: lightgray;
}

.loginAccountButton {
    display: flex;
    width: 420px;
    margin-top: 14px;
    border-top: 1px solid;
    border-color: lightgray;
    align-items: center;
    font-family: "Open Sans";
    background-color: rgba(0, 100, 225, 255);
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    justify-content: center;
    color: white;
    padding: 10px;
  }
.loginAccountButton:hover{
    background-color: #2b7cff;
}
