.AboutUs {
  width: 190px;
  height: 50px;
  font-weight: bold;
}
.AboutUsText {
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.5;
}
@media (max-width: 1366px) {
  .AboutUsText {
    line-height: 1.4;
    font-size: 12px;
  }
}
@media (max-width: 1280px) {
  .AboutUsText {
    line-height: 1.4;
    font-size: 12px;
  }
}

.BoldyText {
  font-weight: bolder;
  padding-bottom: 10px;
  padding-top: 10px;
}
@media (max-width: 1366px) {
  .BoldyText {
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
  }
}
@media (max-width: 1280px) {
  .BoldyText {
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
  }
}

.aboutUsName {
  font-family: "Caveat", cursive;
  font-size: 48px;
}
@media (max-width: 1366px) {
  .aboutUsName {
    font-size: 36px;
  }
}
@media (max-width: 1280px) {
  .aboutUsName {
    font-size: 36px;
  }
}

.AlertBoxHomePage {
  height: 100%;
  background-color: #fdedef;
  border-radius: 4px;
  padding: 10px;
  /* display: flex; */
  align-items: center;
  text-align: left;
  justify-content: center;
  font-family: "Open Sans";
  font-size: 12px;
  color: #ef4d61;
  font-weight: bolder;
}
@media (max-width: 1366px) {
  .AlertBoxHomePage {
    font-size: 10px;
  }
}

.Redalert {
color: rgba(0, 0, 0, 0.6);
padding-top: 6px;
}

@media (max-width: 1280px) {
    .AlertBoxHomePage {
      font-size: 10px;
    }
  }

.Redalert {
  color: rgba(0, 0, 0, 0.6);
  padding-top: 6px;
}
