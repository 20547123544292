.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .popup button {
    margin-left: 10px;
  }
  