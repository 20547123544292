.UNEXCSS {
  display: flex;
  border: none;
  padding: 10px;
  width: 100px;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  color: red;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.UNEXCSS:hover{
    background-color: lightgrey;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

}

.INEXCSS {
  display: flex;
  border: none;
  padding: 10px;
  width: 100px;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  background-color: rgba(0, 100, 225, 255);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.INEXCSS:hover{
    background-color: #2b7cff;
    color: white;
}
