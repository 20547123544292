.GetStartedLP{
    text-decoration: none;
    color: rgba(0,0,0,0.7);
    font-family: "Open Sans";
}
.SignInLP{
    text-decoration: none;
    color: rgba(0, 100, 225, 255);
    border-radius: 4px;
    font-family: "Open Sans";
    color: white;
}
.ExploreLP{
    text-decoration: none;
    color: rgba(0,0,0,0.8);
    font-size: "Open Sans";
}