.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }


  
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    font-family: "Open Sans";
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .popup button {
    margin-left: 10px;
    

  }
  .NCFCSS{
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    font-family: "Open Sans";
    color: red;
    cursor: pointer;
    padding: 8px;
    width: 80px;
  }

  .YCFCSS{
    margin-left: 10px;
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 100, 225, 255);
    color: white;
    cursor: pointer;
    padding: 8px;
    width: 80px;
  }
  
  .ACCF{
    font-size: 18px;
    font-family: "Open Sans";
    font-weight: 400;
    padding-bottom: 20px;
  }

  /* New table css */

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    width: 60%;
    max-width: 360px;
    border-radius: 8px;
  }
  
  