.PasswordCondition {
  margin-top: 10px;
  border: 1px;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Open Sans";
  border-top: 1px solid;
  border-color: lightgray;
  color: darkgray;
}

.condition {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-family: "Open Sans";
}

.condition .tick {
  margin-right: 5px;
  color: green;
  font-size: medium;
  font-family: "Open Sans";
}

.condition.invalid {
  color: red;
  font-family: "Open Sans";
}

/* Other existing CSS styles */

/* Styling for the terms and conditions pop-up */
.TermsPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 500px;
  max-height: 80vh;
  background-color: white;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  font-family: "Open Sans";
}

.TermsPopup.open {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  font-family: "Open Sans";
}

.TermsText {
  overflow-y: auto;
  max-height: 60vh;
  font-family: "Open Sans";
}

.TermsPopupButton {
  margin-top: 10px;
  font-family: "Open Sans";
}
/*----------*/

.signup-card {
  width: 420px;
  margin: 100px auto;
  padding: 20px;
  /* padding-top: 100px; */
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans";
}

.signup-card h3 {
  text-align: center;
  font-family: "Open Sans";
}

.signup-card form {
  display: flex;
  font-family: "Open Sans";
  flex-direction: column;
}

.signup-card .form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Open Sans";
}

.signup-card .form-row label {
  flex-basis: 30%;
  margin-right: 10px;
  font-family: "Open Sans";
}

.signup-card .form-row .input-group input {
  width: 100%;
  font-family: "Open Sans";
}

.signup-card button {
  margin-top: 10px;
  font-family: "Open Sans";
}

/* ------ */

.nameLabels {
  font-size: 12px;
  color: grey;
  font-family: "Open Sans";
}
.nameLabelsTop {
  font-size: 12px;
  color: grey;
  font-family: "Open Sans";
}

.inputLabels {
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  outline: #fff;
  width: 400px;
  font-family: "Open Sans";
}

.inputLabelsTop {
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 10px;
  outline: #fff;
  width: 400px;
  display: flex;
  margin-left: 0;
  display: flex;
  font-family: "Open Sans";
}

.signupHeader {
  font-family: "Open Sans";
  font-weight: bolder;
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  border-color: lightgray;
}

.CreateAccountButton {
  display: flex;
  width: 420px;
  align-items: center;
  font-family: "Open Sans";
  background-color: rgba(0, 100, 225, 255);
  border-radius: 4px;
  padding: 10px;
  border: none;
  cursor: pointer;
  justify-content: center;
  color: white;
}
.CreateAccountButton:disabled {
  background-color: lightgrey;
  pointer-events: none;
  cursor: not-allowed;
}
.CreateAccountButton:hover {
  background-color: #2b7cff;
}

.FormCSS {
  padding-top: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.TermsConditionsCheckbox {
  display: flex;
  font-size: 12px;
  border-top: 1px solid;
  padding-top: 4px;
  border-color: lightgray;
}

.signinlink {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 10px;
}

.LoginLink {
  text-decoration: none;
  cursor: pointer;
  padding-left: 2px;
  color: rgba(0, 100, 225, 255);
}

/* ----- */

.error {
  background-color: #fdedef;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
  padding: 9px;
}

.errorIcon {
  font-size: medium;
  color: #ef4d61;
}

.errorText {
  color: black;
  font-size: 14px;
  font-family: "Open Sans";
  padding-left: 4px;
}

/* Terms and conditions */

.TermsBox {
  font-family: "Open Sans";
  height: 720px;
}
.TCheading {
  font-size: 20px;
  font-weight: bolder;
  color: black;
}
.TCtext {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 4px;
  padding-bottom: 4px;
}
.Semail {
  color: rgba(0, 100, 225, 255);
  font-weight: 600;
}
.AgreeButton {
  background-color: rgba(0, 100, 225, 255);
  color: white;
  border: none;
  border-radius: 4px;
  padding-left: 4px;
  padding-top: 4px;
  font-family: "Open Sans";
  padding: 4px;
  margin: 6px;
  margin-right: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.DisagreeButton {
  background-color: white;
  color: black;
  border: none;
  border-radius: 4px;
  padding-left: 4px;
  padding-top: 4px;
  font-family: "Open Sans";
  padding: 4px;
  margin: 6px;
  margin-right: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.GOTOLOGIN{
  text-decoration: none;
  color: white;
}

.TermsButtonsCss{
  display: flex;
  flex-direction: row;
  justify-content: right;
}