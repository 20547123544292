.tswitch {
  color: darkgray;
}

.ButtonsEditProfile {
  position: relative;
  left: 352px;
  top: 54%;
}
@media (max-width: 1366px) {
  .ButtonsEditProfile {
    position: relative;
    left: 328px;
  }
}

@media (max-width: 1280px) {
  .ButtonsEditProfile {
    position: relative;
    left: 308px;
  }
}

.ButtonsEP {
  border-radius: 8px;
  margin: 2px;
  border: none;
  font-family: "Open Sans";
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 8px;
  width: 100px;
}

.TPSCSSS {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}
