.HeaderCompany {
  font-family: "Open Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 6px;
  font-weight: bolder;
  padding-left: 8px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 40px;
  margin-left: 8px;
}

.HeaderCompanyName {
  padding: 4px;
}

.HeaderButtonIcons {
  background-color: white;
  border-radius: 8px;
  height: 32px;
  margin-right: 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  padding-left: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.HeaderIconButton {
  background-color: white;
  border: none;
  height: 24px;
  cursor: pointer;
  color: black;
  font-family: "Open Sans";
  position: relative;
  border-radius: 14px;
  width: 72px;
  top: 3.5px;
  left: -1px;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.HIcons1 {
  position: relative;
  cursor: pointer;
  font-size: small;
  top: 5px;
  left: 3px;
  border-radius: 0 2px 2px 0;
  color: black;

  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HIcons2 {
  position: relative;
  color: red;
  cursor: pointer;
  font-size: smaller;
  top: 5px;
  left: 3px;
  border-radius: 0 2px 2px 0;

  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CreateInvoiceButton {
  text-decoration: none;
  background-color: rgba(0, 100, 225, 255);
}

.SideBarOptions {
  font-size: 14px;
  text-decoration: none;
  line-height: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  font-family: "Open Sans";
  padding: 4px 12px;
  color: black;
}

@media (max-width: 1366px) {
  .SideBarOptions {
    font-size: 12px;
  }
}

@media (max-width: 1280px) {
  .SideBarOptions {
    font-size: 12px;
  }
}

.SideBarOptions:hover {
  background-color: rgba(0, 100, 225, 255);
  color: white;
  border-radius: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.activeLink {
  background-color: #e6f2fe;
  color: rgba(0, 100, 225, 255);
  font-weight: bolder;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  border-radius: 8px;
  margin-left: 4px;
  margin-right: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.SideBarOptionsSales {
  position: relative;
  font-size: 14px;
  text-decoration: none;
  line-height: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  font-family: "Open Sans";
  padding: 4px 12px;
  color: black;
}

.SideBarOptionsSales:hover {
  background-color: rgba(0, 100, 225, 255);
  color: white;
  border-radius: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.SideBarOptionsSales:hover .ComingSoonBox {
  color: white;
  position: absolute;
  top: 8px;
  right: 242px;
}

/* In your CSS file */
.HSSCSS-selected {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 4px;
  display: flex;
  /* border-radius: 4px; */
  flex-grow: 1;
  width: 112%;
  position: relative;
  left: -38px;
  border-radius: 4px;
}

.HSSCSS-not-selected {
  background-color: white;
  font-size: 13px;
  font-family: "Open Sans";
}

.CINSCCSS {
  position: relative;
  left: 35px;
}

@media (max-width: 1366px) {
  .DDG {
    font-size: 12px;
    position: relative;
    right: -30px;
  }
}

@media (max-width: 1280px) {
  .DDG {
    font-size: 12px;
    position: relative;
    right: -30px;
  }
}
@media (max-width: 1366px) {
  .DDGX {
    font-size: 12px;
    position: relative;
    left: -20px;
  }
}

@media (max-width: 1280px) {
  .DDGX {
    font-size: 12px;
    position: relative;
    left: -20px;
  }
}

.SideBarIconWithLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  row-gap: 2px;
  column-gap: 14px;
  font-weight: 600;
}

.selectedCI-option {
  background-color: lightgrey;
  border-radius: 6px;
}