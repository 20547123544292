.print-hidden {
  border: none;
  background-color: transparent;
}

@media print {
  .IFCP {
    background-color: white;
  }
}

.BillButton {
  display: none;
}

@media print {
  .print-visible {
    display: none;
    background-color: transparent;
  }
}

@media print {
  .actions {
    display: none;
  }
}

.PrintButton {
  display: block;
  justify-content: center;
  border-radius: 6px;
  margin: 2px;
  border: none;
  font-family: "Open Sans";
  font-size: 14px;
  padding: 10px;
  width: 100px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.BilledToInput {
  border-color: lightgrey;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: lightgrey;
  width: 310px;
}

@media print {
  .BilledToInput {
    border: none;
    background-color: transparent;
  }
}

.InvoiceNumber {
  font-family: "Open Sans";
  font-size: 16px;
  position: relative;
  top: 4px;
  left: 0;
}

@media print {
  .InvoiceNumber {
    position: relative;
    top: 8.5px;
  }
}

.InvoiceNumberR {
  font-family: "Open Sans";
  font-size: 14px;
}
@media (max-width: 1366px) {
  .InvoiceNumberR {
    font-size: 12px;
  }
}

@media (max-width: 1280px) {
  .InvoiceNumberR {
    font-size: 12px;
  }
}

.DateLabel {
  font-family: "Open Sans";
  font-size: 16px;
  position: relative;
  top: 2px;
}

.DateInput {
  border-color: lightgrey;
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: lightgrey;
  padding: 10px;
}
@media (max-width: 1366px) {
  .DateInput {
    padding: 5px;
  }
}
@media (max-width: 1280px) {
  .DateInput {
    padding: 5px;
  }
}

@media print {
  .DateInput {
    border: none;
    background-color: transparent;
    position: relative;
    top: -o.5px;
    left: -7px;
  }
}

.FromAddressLabel {
  font-size: 12px;
  font-family: "Open Sans";
}

.BilledToSearch {
  background-color: lightgrey;
  /* border-top: 1px solid; */
  border-color: darkgray;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 14px;
  position: relative;
  top: -3px;
  padding-top: 4px;
  padding-left: 8px;
  padding-bottom: 4px;
  margin-top: 4px;
  margin-right: -2px;
  width: 260.5px;
}
@media (max-width: 1366px) {
  .BilledToSearch {
    font-size: 11px;
    position: relative;
    top: 0px;
    padding-top: 2px;
    padding-left: 4px;
    padding-bottom: 2px;
    margin-top: 2px;
    margin-right: -1px;
    width: 210px;
  }
}

@media (max-width: 1280px) {
  .BilledToSearch {
    font-size: 11px;
    position: relative;
    top: 0px;
    padding-top: 2px;
    padding-left: 4px;
    padding-bottom: 2px;
    margin-top: 2px;
    margin-right: -1px;
    width: 197.5px;
  }
}

.BilledToInput.active {
  /* add your styles here */
  border-color: red;
}

.invoiceFormsInputs {
  border: none;
  background-color: lightgray;
  border-radius: 6px;
  outline: none;
  font-family: "Open Sans";
}

@media print {
  .invoiceFormsInputs {
    background-color: transparent;
  }
}

.invoiceFormsInputsProductName {
  border: none;
  background-color: lightgray;
  border-radius: 6px;
  outline: none;
  font-family: "Open Sans";
  position: relative;
  top: 6px;
}

@media print {
  .invoiceFormsInputsProductName {
    background-color: transparent;
    position: relative;
    top: -1px;
  }
}

.invoiceFormsInputProductSearch {
  background-color: lightgrey;
  /* border-color: darkgray; */
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 14px;
  position: relative;
  top: -8px;
  padding-top: 4px;
  padding-left: 8px;
  padding-bottom: 4px;
  margin-top: 4px;
  width: 129.5px;
}
@media (max-width: 1366px) {
  .invoiceFormsInputProductSearch {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 11px;
    position: relative;
    top: -4px;
    padding-top: 2px;
    padding-left: 4px;
    padding-bottom: 2px;
    margin-top: 4px;
    width: 106.5px;
  }
}

@media (max-width: 1280px) {
  .invoiceFormsInputProductSearch {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 11px;
    position: relative;
    top: -4px;
    padding-top: 2px;
    padding-left: 4px;
    padding-bottom: 2px;
    margin-top: 4px;
    width: 102px;
  }
}

@media print {
  .invoiceFormsInputProductSearch {
    display: none;
  }
}

.BTIF {
  padding-top: 9px;
}

@media (max-width: 1366px) {
  .BTIF {
    padding-top: 4.5px;
  }
}

@media (max-width: 1280px) {
  .BTIF {
    padding-top: 4.5px;
  }
}

.BilledToSearch.selected {
  background-color: #f0f0f0;
  padding-bottom: 10px;
  margin-bottom: -8px;
  margin-top: -3.5px;
}

@media (max-width: 1366px) {
  .BilledToSearch.selected {
    padding-bottom: 5px;
    margin-bottom: -4px;
    margin-top: -3.5px;
    width: 213.5px;
  }
}

@media (max-width: 1280px) {
  .BilledToSearch.selected {
    padding-bottom: 5px;
    margin-bottom: -4px;
    margin-top: -3.5px;
  }
}

.BilledToSearch.NotSELECTED {
  margin-top: -4px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 1366px) {
  .BilledToSearch.NotSELECTED {
    padding-bottom: 2.5px;
    padding-bottom: 2.5px;
    margin-top: -2px;
  }
}

@media (max-width: 1280px) {
  .BilledToSearch.NotSELECTED {
    padding-bottom: 2.5px;
    padding-bottom: 2.5px;
    margin-top: -2px;
  }
}

.ProductNameSearch {
  padding-bottom: 4px;
  padding-top: 4px;
}

@media (max-width: 1366px) {
  .ProductNameSearch {
    padding-bottom: 2px;
    padding-top: 2px;
  }
}

@media (max-width: 1280px) {
  .ProductNameSearch {
    padding-bottom: 2px;
    padding-top: 2px;
  }
}

.ProductNameSearch.SProductslist {
  background-color: #f0f0f0;
  position: relative;
  width: 100%;
  left: -8px;
  padding-left: 8px;
}

@media (max-width: 1366px) {
  .ProductNameSearch.SProductslist {
    width: 100%;
    left: -4px;
    padding-left: 4px;
  }
}

@media (max-width: 1280px) {
  .ProductNameSearch.SProductslist {
    width: 100%;
    left: -4px;
    padding-left: 4px;
  }
}

@media (max-width: 1366px) {
  .ProductNameSearch.NProductslist {
    width: 100%;
  }
}

.Notdisplay {
  display: none;
}

@media (max-width: 1366px) {
  .IndexCSS {
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  .IndexCSS {
    font-size: 13px;
  }
}
